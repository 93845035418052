export default {
  apiKey: 'AIzaSyBEYQnoRmgmb9SHW4OyjXSpsIAldFSxYHk',
  authDomain: 'engage4.firebaseapp.com',
  databaseURL: 'https://engage4.firebaseio.com',
  projectId: 'engage4',
  storageBucket: 'engage4.appspot.com',
  messagingSenderId: '259587820456',
  appId: '1:259587820456:web:85af55925246d5e5a46b9d',
  measurementId: 'G-H58XNEW55P',
};
